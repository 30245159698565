import {useEffect} from 'react'
import {useRouter} from 'next/router'


export default function Custom404(): JSX.Element {

  const router = useRouter()

  useEffect(() => {
    router.push('/')
  })

  return <></>
}
